import * as React from "react";
import Seo from "src/components/Seo";
import Typography from "src/components/Typography";
import { PageProps } from "src/types";

const FailAuth = (props: PageProps) => {
  return (
    <section>
      <Seo pageTitle="Произошла ошибка аутентификации" />
      <div
        style={{
          textAlign: "center",
        }}
      >
        <Typography type="h1">Упс</Typography>
        <Typography type="h5">
          Кажется, в вашем аккаунте не указан email
        </Typography>
      </div>
    </section>
  );
};

export default FailAuth;
